<template>
  <div>
    <UserSettings ref="userSettingsDialog" :lockViewerOptions="false" />
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand href="#"
        ><img
          src="../assets/logo.png"
          class="d-inline-block align-top"
          alt="Saince"
          height="30"
      /></b-navbar-brand>
      <!-- Right aligned nav items -->
       <b-dropdown class="ml-1" title="Customer List" disabled :size="buttonSize" variant="info">
        <template #button-content>
          <b-icon icon="building"></b-icon>
          <span v-if="customerId!=''"> {{ customers[customerId].name }}</span>
          <span v-else> TBD</span>
        </template>
        <b-dropdown-item v-for="c in customerIds" :key="c">
          <span v-if="c==customerId"><b-icon icon="toggle-on" variant="info"></b-icon> <strong>{{ customers[c].name }}</strong></span>
          <span v-if="c!=customerId"><b-icon icon="toggle-off"></b-icon> {{ customers[c].name }}</span>
        </b-dropdown-item>
      </b-dropdown>
      <b-navbar-nav class="ml-auto">
        <b-button-group class="ml-2" :size="buttonSize">
          <b-button
            v-if="isCreateUser"
            @click="$emit('on-back')"
            variant="secondary"
            title="Return to user adminstration"
          >
            <b-icon icon="arrow-left"></b-icon> Back
          </b-button>
          <b-button
            v-else
            @click="handleClose"
            variant="secondary"
            title="Return to Worklist"
          >
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
        <b-dropdown class="ml-1" title="User" :size="buttonSize" right>
          <template #button-content>
            <b-icon icon="person"></b-icon>
          </template>
          <b-dropdown-header>{{ user }}</b-dropdown-header>
          <b-dropdown-item @click="userSettings"
            ><b-icon icon="gear-fill"></b-icon> Settings</b-dropdown-item
          >
          <b-dropdown-item @click="profile"
            ><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item
          >
          <b-dropdown-item @click="logout"
            ><b-icon icon="box-arrow-right"></b-icon> Logout</b-dropdown-item
          >
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>
<script>
import UserSettings from "./UserSettings.vue";

export default {
  name: "UserAdministrationNavBar",
  components: {
    UserSettings,
  },
  props: {
    isCreateUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonSize: "sm",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
  },
  computed: {
    reportItemsCount() {
      return this.$store.state.mgmtReportItems.length;
    },
    reportTS() {
      return this.$store.state.mgmtReportTS;
    },
    lastRun() {
      return this.reportTS == 0
        ? "N/A"
        : new Date(this.reportTS).toLocaleString(this.$store.state.locale);
    },
    user() {
      return this.$store.getters.user || "User Name Not Set";
    },
     customerId() {
      return this.$store.state.customerId
    },
     customerIds() {
      return Object.keys(this.$store.state.customers)
    },
    customers() {
      return this.$store.state.customers
    },
  },
  methods: {
    handleClose() {
      // Clean up any temporary worklist created to view a study. Worklist will refresh
      // when mounted.
      //
      this.$store.commit("changeWorklist", []);
      this.$store.commit("changeWorklistSorted", []);
      this.$router.replace("worklist");
    },
    handleResize() {
      this.buttonSize = window.innerWidth < 767 ? "sm" : "md";
    },
    logout() {
      this.$router.replace("logout");
    },
    userSettings() {
      this.$refs.userSettingsDialog.show();
    },
    profile() {
      try {
        window.open(this.$store.state.keycloak.createAccountUrl());
      } catch (err) {
        this.$log.error("Failed to open account profile: " + err);
      }
    },
  },
};
</script>
<style scoped></style>
