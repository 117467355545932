<template>
  <b-modal
    :id="deleteUserInfo.id"
    title="Delete User"
    size="lg"
    header-bg-variant="secondary"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    content-class="shadow"
    hide-footer
    @hide="resetDeleteUserModalFunc"
  >
    <b-row>
      <b-col class="mx-3">
        <h4>
          Are you sure you want to delete user with
          <strong>Username: {{ deleteUserInfo.content }}</strong>
        </h4>
      </b-col>
    </b-row>
    <b-row align-h="end"
      ><b-col cols="auto">
        <b-button class="mt-3 mr-1" variant="" @click="cancelDelete" :disabled="isLoading"
          >Cancel</b-button
        >
        <b-button class="mt-3" variant="danger" @click="deleteUser" :disabled="isLoading"
          >
          <b-icon
                  v-if="isLoading"
                  icon="circle-fill"
                  animation="throb"
                  font-scale="1"
                ></b-icon>
          Delete User</b-button
        >
      </b-col></b-row
    >
  </b-modal>
</template>

<script>
import permissions from "../common/permissions"
import userAdministrationWebServices from "../common/userAdministrationWebServices";

export default {
  props: {
    deleteUserInfo: Object,
    resetDeleteUserModal: Function,
    user: Object,
  },
  data(){
    return{
      isLoading:false,
    }
  },
  methods: {
    resetDeleteUserModalFunc() {
      if (this.resetDeleteUserModal == "Function") {
        this.resetDeleteUserModal();
      }
    },
    async deleteUser() {
      this.isLoading=true
      var alertMsg = `Deleted user account [${this.user.username}].`
      var alertType = "success"

      // First, attempt to delete user account. This is expected to fail if user is associated with more than one customer
      // and current user is not super admin.
      //
      try {
        await userAdministrationWebServices.deleteUser(this.user.id);
        this.$emit("on-delete");
      } 
      catch (error) {
        if (permissions.isSuperAdmin()) {
          this.$log.error(`Error while deleting user: ${error.message}`)
          alertMsg = `Unable to delete user account [${this.user.username}].`
          alertType = "danger"
        }
        else {
          this.$log.warn(`Error while deleting user, most likely as user is associated with multiple customers (will remove access to customer): ${error.message}`)

          // For customer-level admin that cannot delete user, update user account to remove access to all tenants.
          //
          try {
            // Delete user from this customer by updating so no associated tenants.
            //
            var userInfo = { 
              id: this.user.id,
              username: this.user.username,
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email,
              mobileNumber: this.user.mobileNumber,
              customers: {
                [this.$store.state.customerId]: {
                  permissions: [],
                  tenants: {}
                }
              }
            }
            await userAdministrationWebServices.updateUser(userInfo);
            this.$emit("on-delete");
          } 
          catch (error) {
            this.$log.error(`Error while deleting user: ${error.message}`)
            alertMsg = `Unable to delete user account [${this.user.username}].`
            alertType = "danger"
          }
        }
      }
      finally {
        this.$bvModal.hide(this.deleteUserInfo.id);
        this.isLoading=false
        this.$bvToast.toast(
          alertMsg,
          {
            autoHideDelay: 5000,
            solid: true,
            title: "User Administration",
            variant: alertType,
          }
        );
      }
    },
    cancelDelete(){
        this.$bvModal.hide(this.deleteUserInfo.id);
    }
  },
};
</script>

<style></style>
